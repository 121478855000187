import React from 'react';
import { Helmet } from 'react-helmet';

import HeaderCommon from 'sections/ScreverOcxProgram/HeaderCommon';
import Vote from 'sections/ScreverBtqProgram/Vote';
import FooterCommon from 'sections/ScreverOcxProgram/FooterCommon';

const PAGES = [
  {
    title: 'Program',
    url: '/events/screver-btq-program/program',
  },
  {
    title: 'Cinema',
    url: '/events/screver-btq-program/cinema',
  },
  {
    title: '✌🏻feedback',
    url: '/events/screver-btq-program/feedback',
  },
];

const CasinoInstructions = () => {
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: 'en',
        }}
        title="Screver BTQ Event | Vote"
      >
        <meta name="description" content="Vote for the challenge" />

        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Epilogue:wght@400;500;600;700;800;900&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <div className="scr-ocx-program scr-btq-event">
        <HeaderCommon
          title="Hackathons. Rate a challenge for:"
          url="/events/screver-btq-program/"
        />

        <main>
          <div className="ocx-prog-wrap">
            <Vote />
          </div>
        </main>

        <FooterCommon linksToPages={PAGES} />
      </div>
    </>
  );
};

export default CasinoInstructions;
